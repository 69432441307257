<template>
	<div class="page">
		<div class="block60"></div>
		<!-- 专题数据库 -->
		<div class="huaxue_type_more w1239">
			<div class="title fontsize38">专题数据库</div>
			<div class="morediv" @click="$util.routerPath('/analysis_sys')">
				<div class="more fontsize22">查看更多</div>
				<img class="img" src="../assets/static/more_icon.png"/>
			</div>
		</div>
		<div class="huaxue_analysis_box w1260">
			<div class="database_box" v-for="(item,index) in databaseList" :key="index">
				<div class="img"><img src="../assets/static/imagebg.png" /></div>
				<div class="database_con" @click="$util.routerPath('/analysis_sys_page',{typeId:item.id})">
					<div class="title fontsize22">{{item.name}}</div>
					<div class="more fontsize16" v-html="'去查看 >'"></div>
				</div>
			</div>
		</div>
		<div class="block60"></div>
		<!-- 经典案例 -->
		<div class="huaxue_analysis_anli w1239" v-if="anliTypeList.length>0">
			<div class="anli_type">
				<div class="img"><img src="../assets/static/anli_bg.png"/></div>
				<div class="title fontsize30">经典案例</div>
				<div class="desc fontsize14">CLASSIC CASE</div>
			</div>
			<div class="anli_type_box">
				<div class="type_item" v-for="(item,index) in anliTypeList" :key="index" @click="handleAnli(index)">
					<div class="text fontsize14" :class="{'hover':index==curanliType}">{{item.title}}</div>
					<div class="line" v-if="index==curanliType"></div>
				</div>
			</div>
			<div class="anli_con">
				<div class="title fontsize24">{{anliTypeList[curanliType].title}}</div>
				<div class="desc fontsize14">
					<div v-html="anliTypeList[curanliType].contents"></div>
				</div>
			</div>
		</div>
		<!-- 知识产权情报 -->
		<div class="block60"></div>
		<div class="huaxue_analysis_news w1260">
			<div class="type_more">
				<div class="title fontsize38">知识产权情报</div>
				<div class="img"><img src="../assets/static/new_icon1.png"/></div>
				<div class="more fontsize22" @click="$util.routerPath('/infoItem',{type:'zsqingbao'})">查看更多</div>
			</div>
			<div class="datalist">
				<div class="data_item" v-for="(item,index) in newsList" :key="index">
					<div class="img">
						<img :src="item.imgUrl || require('../assets/static/banner1.png')">
					</div>
					<div class="data_con">
						<div class="title clamp2 fontsize16">{{item.title}}</div>
						<div class="timer fontsize14">{{item.releaseTime || item.createDate}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			databaseList:[],
			anliTypeList:[],
			curanliType:0,//当前
			newsList:[],
		};
	},
	mounted: function() {
		this.pagedatabaseType()
		this.getJingdiananli()
		this.getChanquanqingbao()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取专题数据库
		pagedatabaseType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 4,
				state:0,//	状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/patent/pageType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.databaseList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//案例点击
		handleAnli(index){
			this.curanliType = index
		},
		//(文章)经典案例
		getJingdiananli() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888,
				state:0,//状态 0上架 1下架
			};
			this.$http.post('frontEnd/patent/getJingdiananli', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.anliTypeList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获得知识产权情报
		getChanquanqingbao() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 4,
			};
			this.$http.post('frontEnd/patent/getChanquanqingbao', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.newsList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
